@font-face {
  font-family: "Raleway-Black";
  src: url("./fonts/Raleway-Black.woff") format("woff"),
    url("./fonts/Raleway-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("./fonts/Raleway-ExtraBold.woff") format("woff"),
    url("./fonts/Raleway-ExtraBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("./fonts/Raleway-Bold.woff") format("woff"),
    url("./fonts/Raleway-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-ExtraLight";
  src: url("./fonts/Raleway-ExtraLight.woff") format("woff"),
    url("./fonts/Raleway-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway-Regular.woff") format("woff"),
    url("./fonts/Raleway-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("./fonts/Raleway-Medium.woff") format("woff"),
    url("./fonts/Raleway-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-SemiBold";
  src: url("./fonts/Raleway-SemiBold.woff") format("woff"),
    url("./fonts/Raleway-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Light";
  src: url("./fonts/Raleway-Light.woff") format("woff"),
    url("./fonts/Raleway-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Thin";
  src: url("./fonts/Raleway-Thin.woff") format("woff"),
    url("./fonts/Raleway-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: #121212;
  color: #fff;
  font-size: 16px;
  position: relative;
}

h1 {
  font-family: "Raleway-Black", Arial, Helvetica, sans-serif;
  font-size: 6.25rem; /* 100px */
  line-height: 117px;
  font-weight: 900;
}

@media (max-width: 768px) {
  h1 {
    font-family: "Raleway-Black", Arial, Helvetica, sans-serif;
    font-size: 3rem; /* 50px */
    line-height: 59px;
    font-weight: 900;
  }
}

h2 {
  font-family: "Raleway-Black", Arial, Helvetica, sans-serif;
  font-size: 4.5rem; /* 75px */
  line-height: 117px;
  font-weight: 900;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  h2 {
    font-family: "Raleway-Black", Arial, Helvetica, sans-serif;
    font-size: 2.5rem; /* 40px */
    line-height: 35px;
    font-weight: 900;
    letter-spacing: 0.02em;
  }
}

h3 {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-size: 2.5rem; /* 40px */
  line-height: 47px;
  font-weight: bold;
  letter-spacing: 0.02em;
}

@media (max-width: 768px) {
  h3 {
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    font-size: 2rem; /* 32px */
    line-height: 35px;
    font-weight: 900;
    letter-spacing: 0.02em;
  }
}

h4 {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-size: 1.4rem; /* 24x */
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-style: normal;
  line-height: 28px;
}

@media (max-width: 768px) {
  h4 {
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    font-size: 1rem; /* 16px */
    line-height: 19px;
    font-weight: 900;
    letter-spacing: 0.02em;
  }
}

h5 {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-size: 1rem; /* 16px */
  line-height: 23px;
  font-weight: 700;
  line-height: 19px;
}

@media (max-width: 768px) {
  h5 {
    font-size: 0.8rem; /* 14px */
    line-height: 19px;
    font-weight: 700;
  }
}

input {
  font-family: "Raleway-Bold", Arial, Helvetica, sans-serif;
  line-height: 23px;
  font-weight: 700;
  background-color: #121212;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 0.8rem; /* 14px */
  font-family: "Raleway-SemiBold", Arial, Helvetica, sans-serif;
}

span {
  font-family: "Raleway-Black", Arial, Helvetica, sans-serif;
  font-size: 6.25rem; /* 100px */
  line-height: 0px;
  font-weight: 900;
  color: transparent;
}

@media (max-width: 768px) {
  span {
    font-family: "Raleway-Black", Arial, Helvetica, sans-serif;
    font-size: 3rem; /* 50px */
    line-height: 0px;
    font-weight: 900;
    color: transparent;
  }
}

.letteroutline {
  /* text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; */
  -webkit-text-stroke: 1px #fff;
}

/* .swiper-wrapperadd {
  height: 500px;
} */

.swiper-pagination {
  color: white !important;
  bottom: -50px;
  position: absolute;
}
